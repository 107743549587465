/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "ngx-toastr/toastr";
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "./dynamic-theme/main.scss";

body {
  margin: 0px;
  padding: 0px;
  color: #b0b0b0 !important;
  background-color: map-get($theme-colors, "body-bg-color") !important;
  font-family: "Rubik", sans-serif;
  // font-size: 1rem = 10px;
}

.main:has(.isLoginMain) {
  padding: 0px 15% !important;
}

@media screen and (max-width: 912px) {
  .main:has(.isLoginMain) {
    padding: 0px 10% !important;
  }
}
@media screen and (max-width: 480px) {
  .main:has(.isLoginMain) {
    padding: 0px !important;
  }
}
.main:has(.isLoanStepMain) {
  .main-container {
    padding: 0px;
  }
}
.main:has(.isHomeLayout) {
  .main-container {
    padding: 0px;
  }
}

.content-main:has(.timeline-card-view) {
  padding-bottom: 15px;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  min-width: 50vw;
  border-radius: 11px !important;
  background: none !important;
}

.card-view {
  // background-color: #283A48;
  background-image: map-get($theme-colors, "card-bg-color");
  border: 1px solid map-get($theme-colors, "card-border-color");
  color: map-get($theme-colors, "primary-text-color");
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  &-title {
    color: map-get($theme-colors, "card-title-color");
    font-size: map-get($theme-colors, "card-title-font-size");
    font-weight: 400;
    margin: 0%;
  }
  .card-sub-title {
    line-height: normal;
    color: map-get($theme-colors, "card-title-color");
    font-size: map-get($theme-colors, "text-size-extra-medium");
    font-weight: 500;
    margin: 0px;
  }
  .text-content {
    margin: 0px;
    line-height: normal;
    font-size: map-get($theme-colors, "text-size-medium");
  }
  .small-text-content {
    margin: 0px;
    font-size: map-get($theme-colors, "text-size-medium-2");
  }
  .text-center {
    text-align: center;
  }
  .highlight-text {
    font-weight: 500;
  }

  .icon-box {
    background: map-get($theme-colors, "primary-transparent-bg-color");
  }

  &-button {
    width: auto;
    height: 40px;
    color: map-get($theme-colors, "button-text-color");
    background-image: map-get($theme-colors, "button-bg-color");
    border: none;
    border-radius: 7px;
    font-size: map-get($theme-colors, "text-size-medium");
    padding: 10px 100px;
    font-weight: 500;
    cursor: pointer;
    transition: 1s;
    outline: none;
  }

  &-button:hover {
    // background-color: map-get($theme-colors, 'button-hover-color' );
    background-image: map-get($theme-colors, "button-hover-color");
  }

  &-button:disabled {
    background-image: map-get($theme-colors, "disable-button-bg-color");
    cursor: not-allowed;
    color: map-get($theme-colors, "disable-button-text-color");
    font-size: 14px;
  }

  .card-view-button-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .card-button {
      width: auto;
      height: 40px;
      color: map-get($theme-colors, "button-text-color");
      background-image: map-get($theme-colors, "button-bg-color");
      border: none;
      border-radius: 7px;
      font-size: map-get($theme-colors, "text-size-medium");
      padding: 10px 100px;
      font-weight: 500;

      cursor: pointer;
      transition: 1s;
      outline: none;
    }
  }
}

.dialog-card-view {
  background-image: map-get($theme-colors, "card-bg-color");
  border: 1px solid map-get($theme-colors, "card-border-color");
  color: map-get($theme-colors, "primary-text-color");
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  max-height: 90vh;
  overflow: auto;
  .highlight-text {
    font-weight: 500;
  }
  .text-content {
    margin: 0px;
    line-height: normal;
    font-size: map-get($theme-colors, "text-size-medium");
  }
  .small-text-content {
    margin: 0px;
    font-size: map-get($theme-colors, "text-size-medium-2");
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-title {
      color: map-get($theme-colors, "card-title-color");
      font-size: map-get($theme-colors, "text-size-large") !important;
      font-weight: 500;
      margin: 5px 0px;
    }

    .icon-box {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: map-get($theme-colors, "primary-transparent-bg-color");
      border-radius: 50%;

      img {
        height: 50%;
        width: 50%;
      }
    }
  }
  .dialog-content {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
    .text-content {
      margin: 0px;
      font-size: map-get($theme-colors, "text-size-medium");
      color: map-get($theme-colors, "primary-text-color");
    }
    .card-sub-title {
      color: map-get($theme-colors, "card-title-color");
      font-size: map-get($theme-colors, "card-title-font-size");
      font-weight: 500;
      margin: 0px;
    }
  }
  .dialog-btn-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .half-btn {
      width: calc(50% - 10px) !important;
    }
    .full-btn {
      width: 100% !important;
    }
    .fit-btn {
      width: fit-content !important;
    }
  }
  .j-content-between {
    justify-content: space-between !important;
  }
  .content-center {
    justify-content: center;
  }
  .text-center {
    text-align: center;
  }

  .f-dir-column {
    flex-direction: column;
    align-items: center;
  }

  &-title {
    color: map-get($theme-colors, "card-title-color");
    font-size: map-get($theme-colors, "card-title-font-size");
    font-weight: 400;
    margin: 0%;
  }

  &-button {
    width: auto;
    height: 40px;
    color: map-get($theme-colors, "button-text-color");
    background-image: map-get($theme-colors, "button-bg-color");
    border: none;
    border-radius: 7px;
    font-size: map-get($theme-colors, "text-size-medium");
    padding: 10px 100px;
    font-weight: 500;
    cursor: pointer;
    transition: 1s;
    outline: none;
  }

  &-button:hover {
    background-image: map-get($theme-colors, "button-hover-color");
  }

  &-button:disabled {
    background-image: map-get($theme-colors, "disable-button-bg-color");
    cursor: not-allowed;
    color: map-get($theme-colors, "disable-button-text-color");
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .card-view {
    padding: 10px 15px;

    &-title {
      font-size: 18px;
      font-weight: 500;
    }

    &-button {
      padding: 5px 40px !important;
    }
    .card-view-button-wrapper {
      .card-button {
        padding: 10px 100px;
        width: 100% !important;
      }
    }
  }
  .dialog-card-view {
    padding: 25px;

    &-title {
      font-size: 18px;
      font-weight: 500;
    }

    &-button {
      padding: 5px 40px !important;
    }
  }
  .content-center {
    justify-content: center;
  }

  .form-control {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 480px) {
  .cdk-overlay-pane {
    max-width: 95vw !important;
  }
  .card-view {
    padding: 15px 20px 20px 20px;

    &-title {
      font-size: 18px;
      font-weight: 500;
    }

    &-button {
      width: 100%;
      padding: 5px 30px !important;
    }
    .card-view-button-wrapper {
      .card-button {
        width: 100% !important;
      }
    }
  }
  .dialog-card-view {
    padding: 25px;
    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-title {
        color: map-get($theme-colors, "card-title-color");
        font-size: map-get($theme-colors, "text-size-extra-medium");
        font-weight: 500;
        margin: 5px 0px;
      }
      .content-center {
        text-align: center;
      }

      .icon-box {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        img {
          height: 50%;
          width: 50%;
        }
      }
    }

    &-title {
      font-size: 18px;
      font-weight: 500;
    }

    &-button {
      width: 100%;
      padding: 5px 30px !important;
    }
  }

  .form-control {
    font-size: 14px !important;
  }
}
.main-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  .title {
    color: map-get($theme-colors, "primary-text-color");
    font-size: map-get($theme-colors, "card-title-font-size");
    font-weight: 400;
    margin: 0%;
  }

  .text-content {
    text-align: center;
    color: map-get($theme-colors, "primary-text-color");
    font-size: map-get($theme-colors, "text-size-large-2");
    font-weight: 400;
    margin: 0px;
  }
}

.secondary-btn {
  width: auto;
  height: 40px;
  color: map-get($theme-colors, "secondary-button-text-color");
  background: map-get($theme-colors, "secondary-button-bg-color");
  border: none;
  border-radius: 7px;
  font-size: map-get($theme-colors, "text-size-medium");
  padding: 10px 100px;
  font-weight: 500;
  cursor: pointer;
  transition: 1s;
  outline: none;
  &:hover {
    background: map-get($theme-colors, "secondary-button-hover-color");
  }
}
//bootstrap form customer style
.form-control,
.form-control:focus,
.form-select,
.form-select:focus {
  background-color: map-get($theme-colors, "input-bg-color");
  color: map-get($theme-colors, "input-text-color");
  border-color: map-get($theme-colors, "input-border-color");
  height: 40px;
}

.form-control:focus {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 15px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.form-check-input:focus {
  box-shadow: none !important;
}
.form-check-input {
  cursor: pointer;
  background-color: map-get($theme-colors, "body-bg-color");
  border-color: map-get($theme-colors, "dark-border-color") !important;
}

.form-check-input:checked {
  background-color: map-get($theme-colors, "success-bg-color");
  border-color: map-get($theme-colors, "success-bg-color") !important;
}
.form-select:focus {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 15px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.list-group,
.list-group-item {
  background-color: map-get($theme-colors, "input-bg-color");
  color: map-get($theme-colors, "input-text-color");
  // border: none;
  border-bottom: 1px solid map-get($theme-colors, "secondary-text-color");
}

.list-group-item:hover {
  background-color: map-get($theme-colors, "input-hover-color");
}

::placeholder {
  color: map-get($theme-colors, "input-placeholder-color") !important;
  opacity: 1;
}

.errorMsg {
  color: red;
}

.formInputError {
  border: 1px solid red;
}

// common class
.d-flex {
  display: flex;
}

.f-dir-column {
  flex-direction: column;
}

.j-sb {
  justify-content: space-between;
}

.j-center {
  justify-content: center;
}
.j-start {
  justify-content: flex-start;
}
.a-start {
  align-items: flex-start !important;
}

.a-center {
  align-items: center;
}

.m-0 {
  margin: 0px;
}

.mt-0 {
  margin-top: 0px;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}

.mb-0 {
  margin-bottom: 0px;
}

.mtb-10 {
  margin: 10px 0px !important;
}
.mtb-0 {
  margin: 0px !important;
}

.mtb-20 {
  margin: 10px 0px;
}

.width-100 {
  width: 100%;
}

.width-50 {
  width: 50%;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-dialog-container {
  background: linear-gradient(180.15deg, #192935 0.13%, #162633 100.78%);
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  font-weight: 300;
  border-radius: 15px !important;
}

// input[type="radio"] {
//   background-color: map-get($theme-colors, "radio-input-bg-color") !important;
//   border: 2px solid map-get($theme-colors, "radio-input-border-color") !important;
//   padding: 10px !important;
//   appearance: none !important;
//   border-radius: 50%;
//   cursor: pointer !important;
// }

// input[type="radio"]:checked {
//   background-color: map-get(
//     $theme-colors,
//     "checked-radio-input-bg-color"
//   ) !important;
// }

// .form-check-input {
//   cursor: pointer;
//   background-color: map-get($theme-colors, "body-bg-color");
//   border-color: map-get($theme-colors, "checked-radio-input-bg-color");
// }

// .form-check-input:checked {
//   background-color: map-get($theme-colors, "checked-radio-input-bg-color");
//   border-color: map-get($theme-colors, "checked-radio-input-bg-color");
// }

input[type="file"] {
  height: auto !important;
}
input[type="range"] {
  height: 5px;
  accent-color: #ec5929 !important;
}
input[type="radio"] {
  height: 20px;
  accent-color: #ec5929 !important;
  cursor: pointer !important;
}
input[type="textarea"] {
  min-height: 60px !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
textarea.form-control {
  min-height: 60px !important;
}

// scrollbar-style//
::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(219, 219, 219);
  margin: block;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a7a7a7c0;
  transition: all 1s;
}

/* Handle on hover */
::ng-deep ::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7c0;
}

.accordion {
  --bs-accordion-btn-focus-box-shadow: none !important;
}
.form-control:focus {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 15px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.accordion-button:not(.collapsed) {
  box-shadow: none;
}
